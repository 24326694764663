body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.inactive{
  color: gray;

}

.inactive:hover{
  color: rgb(36, 224, 19);
}

.active{
  color: white;
}

.active:hover{
  color: rgb(23, 243, 78);
}

.home-gred{
  padding-top: 2rem;
  padding-bottom: 8rem;
  background: #3a6186;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #89253e, #3a6186);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #89253e, #3a6186); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.background-image{
  padding-top: 2rem;
  padding-bottom: 8rem;
  height: 20rem;
  background-image: url('../public/images.jpg');
  background-position: center; 
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.background-qr{
  padding-top: 2rem;
  padding-bottom: 8rem;
  height: 20rem;
  background-image: url('../public/qr-image1.jpg');
  background-position: center; 
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.fixed-pos{
  position: relative;
  background-color: white;
  bottom: 5rem;
}

.small-card:hover{
 cursor: pointer;
  background-color: rgba(206, 227, 247, 0.712);
  transition: 1s ease;
}

p{
  font-size: small;
}


@media only screen and (max-width: 768px) {
  .small-on-mob{
    font-size: small;
    width: 10rem;
  }
  .fixed-pos{
    position: relative;
    background-color: white;
    bottom: 0rem;

  }
  
}