/* Icon Css */
.icon-dummy-image{
    width: 300px;
    height: 300px;
}


.button{
    width: 15rem;
    margin: 2rem;
    border: none;
    border-radius: 0.4rem;
    padding: 1rem;
    cursor: pointer;
    font-size: 1.2rem;
    color: white;
    background-color: rgb(81, 110, 187);
}

.button:hover{
    background-color: rgb(74, 146, 209);
}

.button_disable{
    width: 15rem;
    margin: 2rem;
    border: none;
    border-radius: 0.4rem;
    padding: 1rem;
    font-size: 1.2rem;
    color: white;
    background-color: rgb(141, 9, 82);
}

.btn-upload {
    background-color: #fff;
    border: 3px solid #000;
    color: #000;
    padding: 5px 20px;
    border-radius: 10px;
    font-size: 22px;
    font-weight: bold;
  }


